<template>
  <div class="user-box">
    <!-- 顶部搜索 -->
    <search-form @search="searchList" :examRes="-1"></search-form>
    <!-- 黑名单表单 -->
    <el-table
      border
      :data="users"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <el-table-column prop="tempId" label="ID" align="center" width="45" show-overflow-tooltip></el-table-column>
      <el-table-column prop="username" label="姓名" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center" min-width="110"></el-table-column>
      <el-table-column prop="channelName" label="渠道名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="device" align="center" label="登录设备" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createdAt" align="center" label="注册时间" min-width="155" sortable></el-table-column>
      <el-table-column prop="customer" align="center" label="分配客服" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="status"
        align="center"
        label="认证状态"
        width="150"
        class-name="table-verify"
      >
        <template slot-scope="scope">
          <el-tag
            v-for="(tag , index) in verifyList(scope.row)"
            :key="index"
            size="mini"
            :effect="tag.value ? 'dark' : 'plain'"
            :type="tag.value ? '' : 'info'"
            :class="tag.value? 'active' : ''"
          >{{ tag.name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="机审决策结果" width="110" v-if="showRisk">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.risk == 1">机审通过</el-tag>
          <el-tag type="danger" size="mini" v-if="scope.row.risk == 2">机审拒绝</el-tag>
          <el-tag type="info" effect="plain" size="mini" v-if="scope.row.risk == 0">未命中</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            slot="reference"
            circle
            @click="moveOut(scope.$index, scope.row)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            class="view-btn"
            circle
            @click="handleView(scope.row)"
          ></el-button>
          <!--添加备注-->
          <el-button
            type="info"
            size="mini"
            icon="el-icon-edit-outline"
            circle
            @click="handleRemarks(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <!-- 查看 -->
    <view-bar :show-view="showView" @close="closeView" @handleClose="handleClose"></view-bar>
    <!-- 备注 -->
    <edit-dialog
      :isShow="showDialog"
      :flId="flId"
      @editConfirm="editConfirm"
      @editCancel="editCancel">
    </edit-dialog>
  </div>
</template>

<script>
// utils
import service from './api';
import pagination from '@/mixins/pagination';
import view from '@/components/viewBar/view.js';
import editMemo from "@/mixins/editMemo";

// components
import searchForm from './components/search-form';
import viewBar from '@/components/viewBar';
import editDialog from "@/components/viewBar/reports/editDialog";

export default {
  mixins: [pagination, view, editMemo],
  data() {
    return {
      users: [],
      page: {},
      searchData: {},
      showRisk: true,
    };
  },
  components: {
    searchForm,
    viewBar,
    editDialog
  },
  computed: {
    verifyList() {
      return function(row) {
        let { verify_status = {} } = row;
        let { basic_info = false, real_name = false, operator = false } = verify_status;
        return [
          { name: '实名', value: real_name },
          { name: '基础', value: basic_info },
          { name: 'YYS', value: operator }
        ];
      };
    }
  },
  methods: {
    // 初始化
    init() {
      let initData = { pageNum: 1 };
      this.getList(initData);
    },
    // 获取黑名单列表
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getBlackList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          if(item.risk == -1){
            this.showRisk = false;
          }
        });
        this.users = list;
        this.page = page;
      });
    },
    // 查询搜索结果
    searchList(params) {
      let search = Object.assign(params, { pageNum: 1 });
      this.searchData = params;
      return this.getList(search);
    },
    // 移除黑名单
    moveOut(index, row) {
      this.$alert(`是否确认将用户${row.username || row.mobile}永久性移出黑名单?`, '删除', {
        showCancelButton: true
      }).then(res => {
        return service.check({ userId: row.userId, status:0}).then(res => {
          this.$notify({ type: 'success', message: '移除成功！' });
          this.init();
        });
      });
    },
    handleClose(){
      this.setShowView(false);
      this.getList({ pageNum: this.currentPage, pageSize:this.pageSize});
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
.user-box {
  width: 100%;
  .table-verify {
    .el-tag + .el-tag {
      margin-left: 2px;
    }
    .active.el-tag {
      background-color: #5766da;
      color: #fff;
      border: 1px solid #5766da;
    }
  }
  .view-btn {
    margin-left: 10px;
  }
}
</style>
